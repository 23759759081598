<template lang="pug">
  .wc-tables-header.d-flex.justify-content-between.align-items-center
    h5.m-0
      | {{ title }}
    b-button-group(size='sm')
      b-button.shadow-none(
        v-if='actionNew'
        variant='transparent'
        :to='routeNew'
        :title="$t('shared.actions.create')")
        i.far.fa-fw.fa-plus-circle

      b-button.shadow-none(
        variant='transparent'
        @click.stop.prevent='dataCleanFetch'
        :title="$t('shared.actions.reset')")
        i.fal.fa-fw.fa-undo

      b-button.shadow-none(
        variant='transparent'
        @click.stop.prevent="dataFetch"
        :title="$t('shared.actions.reload')")
        i.fal.fa-fw.fa-sync-alt
</template>

<script>
export default {
  name: 'wc-tables-header',
  props: {
    title: {
      type: String,
      required: true,
    },
    actionNew: {
      type: Boolean,
      default: false,
    },
    routeNew: {
      type: [String, Object],
      default: '#',
    },
  },
  methods: {
    dataCleanFetch(event) {
      this.$emit('click-data-clean-fetch', event)
    },
    dataFetch(event) {
      this.$emit('click-data-fetch', event)
    },
  },
}
</script>
