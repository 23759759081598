<template lang="pug">
  .wc-tables-footer.d-flex.justify-content-between.align-items-center
    .counter(v-if='totalItems')
      span(v-if='totalItems <= pPage')
        | {{ $t('shared.lists.displaying') }}
        b
          |  {{ $t('shared.lists.all') }} {{ totalItems }}
        |  {{ $t('shared.lists.records') }}
      span(v-else)
        | {{ $t('shared.lists.displaying') }}
        b
          |  {{ (cPage - 1) * pPage || 1 }}
        |  -
        b
          |  {{ cPage * pPage }}
        |  {{ $t('shared.lists.of') }}
        b
          |  {{ totalItems }}
        |  {{ $t('shared.lists.in_total') }}
    b-pagination(
      v-if='totalItems'
      :total-rows='totalItems'
      :per-page='pPage'
      :value='cPage'
      @input='currentPage')
</template>

<script>
export default {
  name: 'wc-tables-footer',
  props: {
    totalItems: {
      type: Number,
    },
    pPage: {
      type: Number,
      required: true,
    },
    cPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    currentPage(page) {
      this.$emit('update:cPage', parseInt(page))
    },
  },
}
</script>
