import WcTablesHeader from '@components/shared/tables/WcTablesHeader'
import WcTablesFilter from '@components/shared/tables/WcTablesFilter'
import WcTablesFooter from '@components/shared/tables/WcTablesFooter'
import WcTablesWrapperDefault from '@components/shared/tables/WcTablesWrapperDefault'
import { notify } from '@common/notifications/dispatch'
import { alertDestroy } from '@common/alerts/types'

const Table = {
  mixins: [notify, alertDestroy],

  props: {
    wns: {
      type: String,
      default: 'o',
    },
  },

  components: {
    WcTablesHeader,
    WcTablesFilter,
    WcTablesFooter,
    WcTablesWrapperDefault,
  },

  computed: {
    sortingParams() {
      return {
        [`${this.wns}[page]`]: this.pagination.cPage,
        [`${this.wns}[ppage]`]: this.pagination.pPage,
        [`${this.wns}[sort]`]: this.sorting.sort,
        [`${this.wns}[filter]`]: this.filter,
      }
    },
  },

  methods: {
    routeParams() {
      return {
        show: [],
        edit: [],
      }
    },
    apiParams(item = {}) {
      return {
        list: [{}, this.sortingParams],
        destroy: [{ id: this.$getDeep(item, 'attributes.sid') }],
      }
    },
    apiRequestBuilder(action, item = {}, paction = null) {
      return this.apiBase[action](this.$http, ...this.apiParams(item)[paction ? paction : action])
    },
    dataFetch(event) {
      if (event) {
        event.preventDefault()
        /* Remove query params that are null. */
        this.$router.push({
          query: Object.entries({
            ...this.$route.query,
            [`${this.wns}[filter]`]: this.filter,
          }).reduce((a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }), {}),
        })
      }
      this.isLoading = true
      this.items = []
      this.apiRequest = this.apiRequestBuilder(this.apiRequestFetch)
      this.apiRequest.promise
        .then((response) => {
          this.items = response.data
          this.included = response.included
          this.pagination = { ...this.pagination, ...this.$getDeep(response.meta, 'pagination') }
          this.policies = { ...this.policies, ...this.$getDeep(response.meta, 'policies') }
          if (this.pagination.totalPages < this.pagination.cPage) this.pagination.cPage = 1
          if (!this.skipNotifications) this.notifyDispatch(response)
          if (!this.skipPolicies) this.handlePolicies()
          this.isLoading = false
          this.apiCallback('fetch-success', response)
          return true
        })
        .catch((response) => {
          this.policies = { ...this.policies, ...this.$getDeep(response.meta, 'policies') }
          if (!this.skipNotifications) this.notifyDispatch(response)
          if (!this.skipPolicies) this.handlePolicies()
          this.isLoading = false
          this.apiCallback('fetch-error', response)
        })
      this.apiCallback('fetch')
    },
    dataCleanFetch() {
      this.pagination.cPage = 1
      this.pagination.pPage = parseInt(this.$wc.conf.pagination.table_list_pp)
      this.filter = undefined
      this.sorting.sort = undefined
      if (this.$refs[this.wns]) this.$refs[this.wns].$refs.table.localSortBy = undefined
    },
    sortingChanged(ctx) {
      this.sorting.sort = `${this.fields.find((obj) => obj['key'] === ctx.sortBy).attr},${
        ctx.sortDesc ? 'desc' : 'asc'
      }`
    },
    sortingDispatch() {
      let sort = this.$getDeep(this.$route.query, `${this.wns}[sort]`)
      if (sort) {
        this.sorting.sortBy = this.fields.find((obj) => obj['attr'] === sort.split(',')[0]).key
        this.sorting.sortDesc = sort.split(',')[1] === 'desc' ? true : false
      }
    },
    rowDelete(item, index) {
      if (!this.actionDestroy) return
      return this.fireDestroy().then((result) => {
        if (result.value) {
          this.$mergeDeep(this.items[index], true, 'isLoading')
          this.apiRequest = this.apiRequestBuilder('destroy', item)
          this.apiRequest.promise // eslint-disable-line promise/no-nesting
            .then((response) => {
              if (this.$getDeep(response, 'meta.destroyed.soft')) {
                this.$mergeDeep(this.items[index], response.data)
                this.$mergeDeep(this.items[index], false, 'isLoading')
              } else {
                /* Re-test if always exists. Websocket might have already deleted the item. */
                const index = this.items.findIndex(
                  (i) => i.id === response.data.id && i.type === response.data.type
                )
                if (index !== -1) this.items.splice(index, 1)
              }
              if (!this.skipNotifications) this.notifyDispatch(response)
              this.apiCallback('row-delete-success', response)
              return true
            })
            .catch((response) => {
              if (!this.skipNotifications) this.notifyDispatch(response)
              this.$mergeDeep(this.items[index], false, 'isLoading')
              this.apiCallback('row-delete-error', response)
            })
          this.apiCallback('row-delete', item)
        } else {
          this.fireDestroyCancel()
        }
        return true
      })
    },
    rowClicked(item) {
      if (
        this.actionRowClicked &&
        !this.skipPolicies &&
        this.$getDeep(item, 'attributes.policies.show')
      )
        this.$router.push(...this.routeParams(item)[this.actionRowClicked])
    },
    filterClear(event) {
      this.filter = null
      this.dataFetch(event)
    },
    handlePolicies() {
      this.actionDestroy = this.policies.destroy
      let fdelete = this.fields.find((obj) => obj['key'] === 'delete')
      let arr = (this.$getDeep(fdelete, 'class') || '').split(' ')
      if (this.actionDestroy) {
        arr = arr.filter((i) => i !== 'd-none')
      } else {
        if (!arr.includes('d-none')) arr.push('d-none')
      }
      this.$setDeep(fdelete, 'class', arr.join(' '))
    },
    onCancel() {
      this.apiRequest.source.cancel()
    },
    apiCallback(callback) {
      return callback
    },
  },

  watch: {
    $route() {
      this.dataFetch()
    },
    'pagination.cPage'(value) {
      this.$router.push({ query: { ...this.$route.query, [`${this.wns}[page]`]: value } })
    },
    'pagination.pPage'(value) {
      this.$router.push({ query: { ...this.$route.query, [`${this.wns}[ppage]`]: value } })
    },
    'sorting.sort'(value) {
      this.$router.push({ query: { ...this.$route.query, [`${this.wns}[sort]`]: value } })
    },
  },

  data() {
    return {
      isLoading: false,
      apiBase: null,
      apiRequest: null,
      apiRequestFetch: 'list',
      actionDestroy: false,
      actionRowClicked: null,
      skipNotifications: false,
      skipPolicies: false,
      filter: this.$getDeep(this.$route.query, `${this.wns}[filter]`),
      sorting: {
        sort: this.$getDeep(this.$route.query, `${this.wns}[sort]`),
        sortBy: null,
        sortDesc: null,
      },
      pagination: {
        pPageOptions: this.$wc.conf.pagination.table_list,
        cPage: parseInt(this.$getDeep(this.$route.query, `${this.wns}[page]`)) || 1,
        pPage:
          parseInt(this.$getDeep(this.$route.query, `${this.wns}[ppage]`)) ||
          parseInt(this.$wc.conf.pagination.table_list_pp),
        totalItems: null,
        totalPages: null,
      },
      policies: {},
      items: [],
      fields: [],
    }
  },

  mounted() {
    this.sortingDispatch()
    this.dataFetch()
  },
}

export default Table
