<template lang="pug">
  b-form.wc-tables-filter.justify-content-between.mb-4(
    @submit.stop.prevent='filterSubmit'
    inline
    novalidate)
    b-form-group
      b-form-select.shadow(
        size='sm'
        :options='pPageOptions'
        :value='pPage'
        @input.stop.prevent.native="$emit('update:pPage', parseInt($event.target.value))")

    b-form-group.mb-0
      b-input-group(size='sm')
        b-form-input.shadow(
          :value='filter'
          @input.stop.prevent.native="$emit('update:filter', $event.target.value)"
          :placeholder="$t('shared.forms.search')")
        b-input-group-append
          b-button.shadow(
            variant='primary'
            type='submit'
            :title="$t('shared.actions.search')")
            i.fal.fa-fw.fa-search
        b-input-group-append
          b-button.shadow(
            :disabled='!filter'
            @click.stop.prevent='filterClear'
            :title="$t('shared.actions.reset')")
            i.fal.fa-fw.fa-redo
</template>

<script>
export default {
  name: 'wc-tables-filter',
  props: {
    filter: {
      type: String,
      default: '',
    },
    pPage: {
      type: Number,
      required: true,
    },
    pPageOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    filterSubmit(event) {
      this.$emit('submit', event)
    },
    filterClear(event) {
      this.$emit('submit-clear', event)
    },
  },
}
</script>
