<template lang="pug">
  b-table.wc-tables-wrapper-default(
    ref='table'
    :items='items'
    :fields='fields'
    :per-page='0'
    :busy.sync='isLoading'
    v-bind='$attrs'
    v-on='$listeners'
    striped
    hover
    show-empty
    responsive
    no-sort-reset
    no-local-sorting
    no-local-filtering)
    template#table-busy
      .text-center.my-4
        | {{ $t('shared.lists.loading') }}

    template(v-slot:empty='scope')
      .text-center.my-4
        | {{ $t('shared.lists.no_records') }}

    template(v-slot:emptyfiltered='scope')
      .text-center.my-4
        | {{ $t('shared.lists.no_records_filtered') }}

    template(v-if='attrActive' #head(attributes.active))
      i.fal.fa-fw.fa-eye

    template(v-slot:cell()='data' class='data')
      .h-100(v-if='data.field.noClick' @click.stop)
        | {{ $getDeep(data.item, data.field.key) }}
      template(v-else)
        | {{ $getDeep(data.item, data.field.key) }}

    template(v-if='attrActive' v-slot:cell(attributes.active)='data')
      span(v-if='data.value')
        i.fal.fa-fw.fa-eye.text-success
      span(v-else)
        i.fal.fa-fw.fa-eye-slash.text-warning

    template(v-if='actionDelete' v-slot:cell(delete)='data')
      b-button.form-buttons-delete.text-white(
        :disabled="$getDeep(data.item, 'attributes.policies.destroy') === false"
        size='xs'
        :variant="$getDeep(data.item, 'attributes.discarded_at') ? 'secondary' : 'primary'"
        @click.stop.prevent='rowDelete(data.item, data.index, $event)')
        i.fal.fa-fw.fa-trash
        |  {{ $t('shared.actions.delete') }}

    <!-- Pass on all named slots -->
    slot(v-for='slot in Object.keys($slots)' :name='slot' :slot='slot')

    <!-- Pass on all scoped slots -->
    template(v-for='slot in Object.keys($scopedSlots)' v-slot:[slot]='scope')
      slot(:name='slot' v-bind='scope')
</template>

<script>
export default {
  name: 'wc-tables-wrapper-default',
  props: {
    isLoading: Boolean,
    attrActive: Boolean,
    actionDelete: Boolean,
    items: [Array, Function],
    fields: [Array, null],
  },
  methods: {
    rowDelete(item, index, event) {
      if (this.actionDelete) this.$emit('row-delete', item, index, event)
    },
  },
}
</script>
